import axios from "axios";
import { BehaviorSubject } from "rxjs";
const url = "/api/v1/auth/";
const currentUser = new BehaviorSubject(
  JSON.parse(localStorage.getItem("auth"))
);
class AuthService {
  static currentUser() {
    return currentUser.value;
  }
  static getOnline()
  {
      try {

      const val=this.getAuth(JSON.parse(localStorage.getItem("auth")));
      return val
        
      } catch (error) {
        return null
      }
  }

  static getAuth(auth) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(url, {
          headers: {
            "Authorization": "Bearer "+ auth.token
          }
        });
      
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(null);
      }
    });
  }

  static insertx(nusuario) {
    let formData = {
      ddata:nusuario
    };
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return  axios.post(url + "dcs", formData,
    { headers: { "Content-Type": "application/json" }}
    );
  }

  static insert(nusuario, nclave) {
    let formData = {
      nuser:nusuario,
      guilds:nclave
    };
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return  axios.post(url + "save", formData,
    { headers: { "Content-Type": "application/json" }}
    );
  }
}

export default AuthService;