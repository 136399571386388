import router from '@/router'

import AuthService from '@/services/AuthService.js'
import NotificationService from "@/services/NotificationService.js";
export default {
  namespaced: true,
  state: {
    trellob:false,
    auth: null,
    currentUser:{count:6},
    countnot:0,
    notifications:[
      {title:"Debtor Customers More than 5K"}
    ],
    notificacionesCenter:[],
    newNotifications:[],
    hayNuevasNoticias:false
  },
  mutations: {
    userAuth(state, data) {
      if(data.user!=null)
      {
        state.auth = data.user;
        localStorage.setItem("auth", JSON.stringify(data.user));
      }
     else{
     /*   this.commit("user/userLogOut");
        router.go({name:"login"});
        router.app.$root.auth = null;*/
      }
    },
    userLogOut(state) {
      state.auth = null;
      localStorage.removeItem("auth");
    },
    userNotificationsAdd(state,data)
    {
      if(data.user!=null && JSON.stringify(data.user)!=JSON.stringify(state.notificacionesCenter))
      {
        var d=0;
      for(var x=0;x<data.user.length;x++)
      {
        var noti=data.user[x];
        
        if(noti.status==0)
        {
          d++;
        }
      } 

        state.newNotifications=[];
        state.hayNuevasNoticias=false;
        for (let index = 0; index < data.user.length; index++) {
          const element = data.user[index];
          if(state.notifications.indexOf(element)==-1)
          {
            state.newNotifications.push(element); 
            
          }
        }
        if(state.newNotifications.length>0)
        {
          state.hayNuevasNoticias=true;     
        }
        else
        {
          state.hayNuevasNoticias=false;
        }

      state.notifications=[];
      state.notificacionesCenter=[];
      state.notifications.push({title:d>0?"Debtor Customers More than 5K":"You don't have notifications"});
      for(var x=0;x<data.user.length;x++)
      {
        var noti=data.user[x];
        
          if(noti.status==0)
          {
            state.notifications.push(noti);
          }
          state.notificacionesCenter.push(noti);
         // state.notificacionesCenter.push({ divider: true, inset: true,visible:noti.visible });
     
       // state.notifications.push({ divider: true, inset: true,visible:noti.visible });
      }
      state.countnot=d;
      }
    }

  },
  actions: {
    loadNotifUser({
      commit
    }) {
      const user = (JSON.parse(localStorage.getItem("auth")))["username"];

      if (user) {
        return new Promise(async (resolve, reject) => {
          try {
            const data=(await NotificationService.getUserNotifications(user)).data;
            commit("userNotificationsAdd", {
              user: data,
            });
            resolve(data);
          } catch (e) {

            commit("userNotificationsAdd", {
              user: null,
            });
            reject(e);
          }
        });
      }
      return null;
    },
    loadNotification({commit})
    {
      dispatch("loadNotifUser").then(function (response, error) {
        if (error) {
          return;
        }
      });
    },
    autoLogin({
      dispatch
    }) {
      dispatch("bringUser").then(function (response, error) {
        if (error) {
          return;
        }
      });
    },
    login({
      commit
    }, authData) {
      return new Promise(async (resolve, reject) => {
        try {
          const estado = await AuthService.insert(authData.user, authData.pass);
          const data = estado.data;
          if (data["msg"].tp == 1) {
            commit("userAuth", {
              user: data["auth"],
            });
          }
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    removeUser({
      commit
    }) {
      commit("userLogOut");
    },
    bringUser({
      commit
    }) {
      const user = JSON.parse(localStorage.getItem("auth"));

      if (user) {
        return new Promise(async (resolve, reject) => {
          try {
            const estado = await AuthService.getAuth(user);
            const data = estado;

            var x = data["auth"];
            if (x) {
              commit("userAuth", {
                user: x,
              });
              resolve(x);
            }else
            {
              commit("userAuth", {
                user: null,
              });
            }
          } catch (e) {

            commit("userAuth", {
              user: null,
            });
            reject(e);
          }
        });
      }
      return null;
    }
  },
  getters: {
    getUser: (state) => {
      return state.auth;
    },
    isUser: (state, getters) => {
      return getters.getUser != null;
    },
    getNotifications:(state) => {
      return state.notifications;
    },
    getNotificaciones:(state) => {
      return state.notificacionesCenter;
    },
    getNuevasNotificaciones:(state) => {
      return state.newNotifications;
    },
    getEsNotificacionVisible:(state) =>{
      return state.hayNuevasNoticias;

    },
    getCountNotif:(state) => {
      return state.countnot;
    },
    getBoardTrelloCg:(state) => {
      return state.trellob;
    }
  },
}
