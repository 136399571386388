import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueResizeText from 'vue-resize-text';
import VueClipboard from 'vue-clipboard2'
import VueShepherd from 'vue-shepherd';
import "@fortawesome/fontawesome-free/css/all.css"
import "animate.css";
// import 'shepherd.js/dist/css/shepherd.css';
import './cstyle/shepherd.css'
const passport = require("passport");
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'
require("./strategies/discordStrategy");

VueClipboard.config.autoSetContainer = true 
Vue.use(VueClipboard)

Vue.use(VueResizeText)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)


Vue.use(VueShepherd);
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/tscss/tailwind.css')

Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
