import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from "./roles";
import AuthService from "@/services/AuthService"
import store from "@/store"
//const store = require('@/store')

Vue.use(VueRouter)
const user = JSON.parse(localStorage.getItem("auth"));
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [{
    path: '/',
    name: 'home',
    component: () => import('@/views/elite/Home.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('@/views/elite/store/Store.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/autofarm',
    name: 'autofarm',
    component: () => import('@/views/elite/autofarm/autofarm.vue'),
    meta: {
      authorize: [],
    },
  },
  {
    path: '/addstock',
    name: 'addstock',
    component: () => import('@/views/elite/AddStock.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/uploadreport',
    name: 'uploadreport',
    component: () => import('@/views/elite/UploadReport.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/validatebuy',
    name: 'validatebuy',
    component: () => import('@/views/elite/ValidateBuy.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/elite/faq/Faq.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/deletequest',
    name: 'deletequest',
    component: () => import('@/views/elite/DeleteQuest.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/deleteitem',
    name: 'deleteitem',
    component: () => import('@/views/elite/DeleteItem.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  //Todo sales
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
    },
  },
  {
    path: '/login/discord',
    name: 'discordlogin',
    component: () => import('@/views/DiscordLogin.vue'),
    meta: {
    },
  },
  {
    path: '/feedbackdetails',
    name: 'feedbackdetails',
    meta: {
      authorize: [Role.Salei, Role.Manager],
    },
    component: () => import('@/views/elite/FeedbackDetails.vue'),
  },
  {
    path: '/createcomments',
    name: 'createcomments',
    meta: {
      authorize: [Role.Salei, Role.Manager],
    },
    component: () => import('@/views/elite/CreateComments.vue'),
  },
  {
    path: '/goals',
    name: 'goals',
    meta: {
      authorize: [Role.Salei, Role.Manager],
    },
    component: () => import('@/views/elite/Goals.vue'),
  },
  {
    path: '/createquest',
    name: 'createquest',
    meta: {
      authorize: [Role.Manager],
    },
    component: () => import('@/views/elite/CreateQuest.vue'),
  },
  {
    path: '/createairdrop',
    name: 'createairdrop',
    meta: {
      authorize: [Role.Manager],
    },
    component: () => import('@/views/elite/CreateAirdrop.vue'),
  },
  {
    path: '/createitemquest',
    name: 'createitemquest',
    meta: {
      authorize: [Role.Manager],
    },
    component: () => import('@/views/elite/ItemQuest.vue'),
  },
  {
    path: '/validateimages',
    name: 'validateimages',
    meta: {
      authorize: [Role.Manager],
    },
    component: () => import('@/views/elite/ValidateImages.vue'),
  },
  {
    path: '/viewitems',
    name: 'viewitems',
    meta: {
      authorize: [Role.Salei, Role.Manager],
    },
    component: () => import('@/components/ViewItems.vue'),
  },
  {
    path: '/viewairdrop',
    name: 'viewairdrop',
    meta: {
      authorize: [Role.Salei, Role.Manager],
    },
    component: () => import('@/components/ViewAirdrop.vue'),
  },
  {
    path: '/topweek',
    name: 'topweek',
    meta: {
      authorize: [Role.Manager],
    },
    component: () => import('@/views/elite/TopbyWeek.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      authorize: [Role.Manager, Role.Salei, Role.Sale],
    },
    component: () => import('@/views/elite/Profile.vue'),
  },
  {
    path: '/ruffle',
    name: 'ruffle',
    component: () => import('@/views/elite/ruffle/Ruffle.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/stockmisterybox',
    name: 'stockmisterybox',
    component: () => import('@/views/elite/StockMisteryBox.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/mysteryboxgained',
    name: 'mysteryboxgained',
    component: () => import('@/views/elite/MysteryBoxGained.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/gobconverter',
    name: 'convertor',
    component: () => import('@/views/elite/store/Convertor.vue'),
    meta: {
      //rol:user?user["rol"]:"null",
      authorize: [],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  ],
})
router.onError(error => {
  console.log(error.message)
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = AuthService.currentUser();
  if (to.name == "discordlogin") { }
  else if (authorize) {
    if (!currentUser) {
      return next({ path: "/login" });
    }
    if (authorize.length && !authorize.includes(currentUser.rol)) {
      return next({ path: "/" });
    }
  }
  if (currentUser && (to.name == "login" || to.name == "discordlogin")) {
    return next({ path: "/" });
  }
  next();
})
export default router
